
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
// import Documents from "../../../common-components/documents/Documents.vue";
// import CommunicationComponent from "../../../common-components/CommunicationComponent.vue";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
// import LoanInfo from "../../../common-components/LoanInfoComponent.vue";
import LoanProcess from "../../processor/loan-process/LoanProcess.vue";

@Component({
  components: { 
    //   Documents, 
    //   LoanInfo, 
    //   CommunicationComponent,
      LoanProcess 
    }
})
export default class DashBoard extends Vue {
//   public loanOverViewData: any = {};
//   public loanContacts: any = [];

//   public async getLoanContacts() {
//     try {
//       this.$store.state.tmpcoLoader = true;
//       const response = await Axios.get(
//         `${BASE_API_URL}borrower/dashboard/getLoanContacts/${this.$store.state.sessionObj.loanId}`,
//         { headers: authHeader() }
//       );
//       if (response.status === 201) {
//         this.loanContacts = response.data ? response.data : [];
//       }
//       this.$store.state.tmpcoLoader = false;
//     } catch (error) {
//       this.$store.state.tmpcoLoader = false;
//       console.log(error);
//     }
//   }

//   public async loanData() {
//     try {
//       this.$store.state.tmpcoLoader = true;
//       const response = await Axios.get(
//         `${BASE_API_URL}borrower/dashboard/loanData/${this.$store.state.sessionObj.loanId}`,
//         { headers: authHeader() }
//       );

//       if (response.status === 201) {
//         this.loanOverViewData = response.data;
//       }
//       this.$store.state.tmpcoLoader = false;
//     } catch (error) {
//       this.$store.state.tmpcoLoader = false;
//       console.log(error);
//     }
//     // this.$store.state.tmpcoLoader = false;
//   }
  mounted() {
    // this.loanData();
    // this.getLoanContacts();
  }
}
