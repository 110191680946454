
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import Documents from "../../../common-components/documents/Documents.vue";
import SubmissionForm from "./submission-form/SubmissionForm.vue";
import Processor from "./processor/Processor.vue";
import Urla from "../../../common-components/urla/Urla.vue";
import datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

@Component({
  components: {
    Documents,
    SubmissionForm,
    Processor,
    datepicker,
    Urla,
  },
})
export default class Form extends Vue {
  /* eslint-disable */
  public formTabData: any = {
    isFloodInsuranceRequired: null,
  };

  public error: any = {
    show: false,
    msg: null,
  };
  public errors: any = {
    show: false,
    msg: null,
  };

  public openCalendar: any = false;

  public errorMessage = "";
  public notes: string = "";
  public closingDateStatus = false;
  public closingDate: any = null;

  public options = [
    { text: "No", value: false },
    { text: "Yes", value: true },
  ];
  public loanId = this.$route.query.id;

  public async saveDisclosureHandledBy() {
    try {
      if (this.formTabData.isFloodInsuranceRequired === null) {
        this.error.show = true;
        this.error.msg = "Please select an option";
        return;
      }
      if (
        !this.formTabData.idsHandledByTMPCO &&
        !this.formTabData.disclosureSigned
      ) {
        this.errors.show = true;
        this.errors.msg = "You cannot proceed until Disclosure signed.";
        return;
      }
      if (
        this.formTabData.isFloodInsuranceRequired != null
          ? this.formTabData.isFloodInsuranceRequired
            ? this.formTabData.sameAsHazardInsurance != null
              ? true
              : false
            : true
          : false
      ) {
        let res = await Axios.post(
          BASE_API_URL + "broker/saveDisclosureHandledBy",
          {
            ...this.formTabData,
            loanId: this.loanId,
            notes: this.notes,
          },
          { headers: authHeader() }
        );
        if (res.status == 201) {
          this.error = { show: false, msg: null };
          this.$emit("next-tab", 2);
          this.$snotify.success("Information Saved");
          await this.updateClosingDate();
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getDisclosureHandledBy() {
    try {
      let res = await Axios.get(
        BASE_API_URL + "broker/getDisclosureHandledBy/" + this.loanId,
        { headers: authHeader() }
      );
      this.formTabData = res.data;

      this.notes = res.data.notes ? res.data.notes : "";
      this.closingDate = res.data.closingDate.date
        ? new Date(res.data.closingDate.date)
        : null;

      this.error = { show: false, msg: null };
    } catch (error) {
      console.log(error);
    }
  }

  public async updateClosingDate() {
    try {
      let date = this.closingDate;
      const res = await Axios.post(
        BASE_API_URL + "common/updateClosingDate/",
        {
          loanId: this.$route.query.id,
          closingDate: date,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.data.status) {
        this.openCalendar = false;
        this.closingDateStatus = true;
        this.$snotify.success("Closing Date Added  Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    await this.getDisclosureHandledBy();
  }
  /* eslint-disable */
}
