
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";
import Documents from "../../../common-components/documents/Documents.vue";
import SubmissionForm from "./submission-form/SubmissionForm.vue";
import Processor from "./processor/Processor.vue";
import Urla from "../../../common-components/urla/Urla.vue";
import FormTab from "./Form.vue";
@Component({
  components: {
    Documents,
    SubmissionForm,
    Processor,
    Urla,
    FormTab,
  },
})
export default class Loan extends Vue {
  /* eslint-disable */
  public tabIndex = 0;
  public isDisabled = this.$route.query.name != "disclosure" ? true : false;
  public step = this.$route.query.name != "disclosure" ? 0 : 2;
  public loanId = this.$route.query.id;
  public validationStatus = false;
  public isFormTabDisabled = true;
  public agentsNotSelected: any = [];
  public documentValidation = false;
  public contactValidation = false;
  public contactMsg: any = null;
  public agentPos: any = null;

  public async getLoanStatus() {
    let res = await Axios.get(
      BASE_API_URL + "common/getLoanStatus/" + this.$route.query.id,
      {
        headers: authHeader(),
      }
    );
    if (res.data.applicationDate) {
      this.$modal.show("loan-status-modal");
    }
  }

  public enableTabs(data) {
    this.isDisabled = this.isFormTabDisabled = data == 0 ? true : false;
    this.step++;
    setTimeout(() => {
      this.tabIndex = data;
    }, 1);
  }

  public async checkTabs() {
    if (this.$route.query.name != "disclosure") {
      this.isFormTabDisabled = this.tabIndex == 0 ? true : false;
      this.isDisabled = this.tabIndex == 0 || this.tabIndex == 1 ? true : false;
    }
  }

  public async sentToProcessor() {
    await this.checkValidation();
    if (this.documentValidation) {
      this.tabIndex = 0;
      this.step = 2;
    } else if (this.validationStatus) {
      this.tabIndex = 1;
      this.step = 3;
    } else {
      let response = await Axios.get(
        BASE_API_URL +
          "processor/getDisclosureReceivedInfo/" +
          this.$route.query.id,
        { headers: authHeader() }
      );
      let idsReceivedData = response.data;
      idsReceivedData.isSigned = true;

      let res = await Axios.post(
        BASE_API_URL + "processor/saveDisclosureDetails",
        {
          loanId: this.loanId,
          initialDisclosureReceived: idsReceivedData,
          taskId: 2,
          userType: this.$store.state.sessionObj.userType,
          isComplete: false,
        },
        {
          headers: authHeader(),
        }
      );
      if (res.status == 201) {
        this.$snotify.success("Disclosure has been signed.");
        await this.NotifyAboutDiscloserDoc();
        if (this.$route.query.name === "disclosure") {
          this.$router.push("/broker-dashboard");
        }
      }
    }
  }

  public async checkValidation() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "broker/checkDisclosureValidation/" +
          this.$route.query.id,
        {
          headers: authHeader(),
        }
      );
      this.validationStatus = res.data.validationStatus;
      this.agentsNotSelected = res.data.agentsNotSelected;
      this.documentValidation = res.data.documentValidation;
    } catch (error) {
      console.log(error);
    }
  }

  public async checkContactValidation() {
    try {
      let res = await Axios.get(
        BASE_API_URL + "broker/checkContactValidation/" + this.$route.query.id,
        {
          headers: authHeader(),
        }
      );

      this.contactValidation = res.data.contactValidation;
      this.agentsNotSelected = res.data.agentsNotSelected;
      if (this.contactValidation) {
        this.tabIndex = 3;
        this.step = 3;
      } else {
        let agentTypes = this.$refs.submission["agentTypes"];

        let max = agentTypes[0].position;

        agentTypes.forEach((e) => {
          if (e.position > max) max = e.position;
        });
        console.log(max, this.agentPos, "mas");
        if (this.agentPos == 6) {
          this.tabIndex = 4;
          this.step = 4;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async NotifyAboutDiscloserDoc() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
          "loanChecklist/notifyProcessorAboutUploadedDocs/" +
          this.$route.query.id +
          "/" +
          "All",
        {
          headers: authHeader(),
        }
      );
      if (res.status === 201) {
        this.$snotify.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async checkForVal() {
    if (this.$route.query.name == "disclosure") {
      this.tabIndex += this.tabIndex;
      this.step += this.step;
    }
  }

  async mounted() {
    if (this.$route.query.name != "disclosure") {
      await this.getLoanStatus();
    }
    EventBus.$on("validation", () => {
      this.checkValidation();
    });
    EventBus.$on("contactValidation", (data) => {
      this.agentPos = data;
      this.checkContactValidation();
    });
  }
  /* eslint-disable */
}
